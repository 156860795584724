import React, { useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "../Toolbar/Toolbar";
import Home from "../Home/Home";
import Login from "../Login/Login";
import Systems from "../Systems/Systems";
import Provision from "../Provision/Provision";
import About from "../About/About";
import ErrorReports from "../ErrorReports/ErrorReports";
import userContext from "../../userContext";

const Redirect = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { currentUser, loading } = useContext(userContext);

  if (location.pathname === "/login" && currentUser) {
    history.push("/");
  }

  if (
    currentUser === null &&
    loading === false &&
    location.pathname !== "/login"
  ) {
    history.push("/login");
  }

  return null;
};

const Main = (props) => {
  return (
    <BrowserRouter>
      <Redirect />
      <AppBar position="static">
        <Toolbar />
      </AppBar>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/systems">
          <Systems />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/provision">
          <Provision />
        </Route>
        <Route path="/reports">
          <ErrorReports />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Main;
