import React from "react";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

const DeleteSystemDialog = (props) => {
  const [password, setPassword] = useState(null);

  const handlePasswordEnter = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>Delete System {props.name}</DialogTitle>
      <DialogContent>
        <TextField
          label="System API Key"
          variant="outlined"
          type="password"
          required
          onChange={handlePasswordEnter}
          fullWidth
          helperText="Please enter the system API key"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => props.handleDelete(props.id, password)}
          disabled={!password}
          color="secondary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSystemDialog;
