import axios from "../../axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import userContext from "../../userContext";

const useStyles = makeStyles({
  loginForm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "5px",
  },
  errorMessage: {
    color: "#ed2525",
    fontSize: "12px",
    maxWidth: "320px",
  },
});

const Login = (props) => {
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [hasError, setHasError] = useState(false);
  const history = useHistory();

  const classes = useStyles();

  const handleUserNameEnter = (e) => {
    setUserName(e.target.value);
  };

  const handlePasswordEnter = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e, setUser) => {
    e.preventDefault();
    setHasError(false);
    await axios
      .post("/api/login/", { username: userName, password: password })
      .then((r) => {
        console.log(r.data);
        setUser(r.data);
        history.push("/");
      })
      .catch((e) => {
        setHasError(true);
        console.log(e);
        console.error(e);
      });
  };

  return (
    <userContext.Consumer>
      {(value) => (
        <div>
          <h3>Login</h3>
          <form
            onSubmit={(e) => handleLogin(e, value.setUser)}
            className={classes.loginForm}
          >
            <div>
              <TextField
                label="User Name"
                variant="filled"
                onChange={handleUserNameEnter}
                fullWidth
                required
              />
            </div>
            <div>
              <TextField
                label="Password"
                variant="filled"
                type="password"
                onChange={handlePasswordEnter}
                fullWidth
                required
              />
            </div>
            {hasError && (
              <div className={classes.errorMessage}>
                Login Failed - please check your username and password, or
                contact support@flirconservator.com for support.
              </div>
            )}
            <div className={classes.button}>
              <Button
                type="submit"
                variant="outlined"
                disabled={!userName || !password}
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      )}
    </userContext.Consumer>
  );
};

export default Login;
