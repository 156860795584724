import React from "react";

const Home = (props) => {
  return (
    <div className="Home">
      <h3>Home</h3>
    </div>
  );
};

export default Home;
