import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import userContext from "../../userContext";
import Logo from "./Logo";

const useStyles = makeStyles({
  appBar: {
    backgroundColor: "#000000",
    fontFamily: "Industry Book, sans-serif",
    color: "#ffffff",
  },
  link: {
    color: "#ffffff",
  },
  title: {
    flexGrow: 1,
  },
  hlist: {
    "padding-top": "20px",
    "& li": {
      "padding-right": "20px",
      display: "inline",
    },
  },
});

const MainToolbar = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const logout = (setUser) => {
    axios.post("/api/logout").then(() => {
      setUser(null);
      history.push("/login");
    });
  };

  return (
    <userContext.Consumer>
      {(value) => (
        <Toolbar className={classes.appBar}>
          <Logo />
          <Typography variant="h6" className={classes.title}>
            Spectrum AI Tools
          </Typography>
          {value.currentUser && (
            <ul className={classes.hlist}>
              <li>
                <NavLink to="/" className={classes.link}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/systems" className={classes.link}>
                  Systems
                </NavLink>
              </li>
              <li>
                <NavLink to="/provision" className={classes.link}>
                  Provision System
                </NavLink>
              </li>
              <li>
                <NavLink to="/reports" className={classes.link}>
                  Error Reports
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" className={classes.link}>
                  About
                </NavLink>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => logout(value.setUser)}
                  className={classes.link}
                >
                  Logout
                </a>
              </li>
            </ul>
          )}
        </Toolbar>
      )}
    </userContext.Consumer>
  );
};

export default MainToolbar;
