import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  circularProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  tableContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  tableDiv: {
    maxWidth: "650px",
  },
  tableBody: {
    width: "100%",
  },
});

const About = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(true);

  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await axios
        .get("/api/sysinfo") // if not works, try sysinfo/context
        .then((r) => {
          console.log(r.data);
          setData(r.data);
          setLoading(false);
          setReload(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
        });
    };
    if (reload) {
      fetchData();
    }
  }, [reload, history]);

  const handleClearError = () => {
    setError(null);
  };

  const parseDate = (dateString) => {
    const date = new Date(dateString * 1000);
    return `${date}`;
  };

  const parseUptime = (s) => {
    let remain = s;

    let days = Math.floor(remain / (60 * 60 * 24));
    remain = remain % (60 * 60 * 24);

    let hours = Math.floor(remain / (60 * 60));
    remain = remain % (60 * 60);

    let minutes = Math.floor(remain / 60);
    remain = remain % 60;

    let seconds = Math.floor(remain);

    const uptime = `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;

    return uptime;
  };

  return (
    <div className="Systems">
      <h3>About</h3>
      {error && (
        <div>
          <div>Error: {error.message}</div>
          <div>
            <Button variant="outlined" onClick={handleClearError}>
              Clear Error
            </Button>
          </div>
        </div>
      )}
      {!error && (
        <React.Fragment>
          {loading && (
            <div className={classes.circularProgress}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
            <div className={classes.tableContainer}>
              <div className={classes.tableDiv}>
                <Table size="small" className={classes.table}>
                  <TableBody className={classes.tableBody}>
                    <TableRow>
                      <TableCell>
                        <strong>Host Name:</strong>
                      </TableCell>
                      <TableCell>{data.hostname}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>S3 Bucket:</strong>
                      </TableCell>
                      <TableCell>{data.s3_bucket}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Service Hostname:</strong>
                      </TableCell>
                      <TableCell>{data.service_hostname}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Time:</strong>
                      </TableCell>
                      <TableCell>{parseDate(data.time)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Uptime:</strong>
                      </TableCell>
                      <TableCell>{parseUptime(data.uptime)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Git Version:</strong>
                      </TableCell>
                      <TableCell>{data.version}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default About;
