import React from "react";
import userContext from "../userContext";
import axios from "../axios";
import Main from "./Main/Main";

class App extends React.Component {
  state = {
    currentUser: null,
    seconds: 0,
    loading: true,
    error: null,
    activeTimer: false,
    setUser: null,
  };

  setUser = (user) => {
    console.log("Set User");
    this.setState({
      currentUser: user,
    });
  };

  createTimer = () => {
    console.log("Creating timer");
    setTimeout(() => {
      console.log("tick");
      this.fetchData();
      this.setState({ seconds: this.state.seconds + 1, activeTimer: false });
      this.createTimer();
    }, 60000); //Setting this a bit shorter for debugging
  };

  fetchData = async () => {
    this.setState({ loading: true });
    await axios
      .get("/api/current_user")
      .then((r) => {
        console.log("fetched data", r.data);
        this.setState({
          currentUser: r.data,
          seconds: 0,
          loading: false,
          error: null,
        });
      })
      .catch((e) => {
        this.setState({
          currentUser: null,
          loading: false,
          error: e,
        });
        //history.push("/Login");
      });
  };

  componentDidMount() {
    console.log("Component mount fetch");
    this.fetchData();
    this.createTimer();
    this.setState({ setUser: this.setUser });
  }

  render() {
    return (
      <userContext.Provider value={this.state}>
        <Main />
      </userContext.Provider>
    );
  }
}

export default App;
