import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import DeleteSystemDialog from "../Dialogs/DeleteSystemDialog";

const useStyles = makeStyles({
  circularProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
});

const Systems = (props) => {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [systemName, setSystemName] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(true);

  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading(true);
      await axios
        .get("/api/system")
        .then((r) => {
          setData(r.data);
          setDataLoading(false);
          setReload(false);
        })
        .catch((e) => {
          if (e.response && e.response.status === 403) {
            history.push("/Login");
          } else {
            console.log(e);
            setError(e);
          }
        });
    };
    if (reload) {
      fetchData();
    }
  }, [reload, history]);

  const handleDeleteSystem = async (serial, password) => {
    setDeleteDialogIsOpen(false);
    await axios({
      method: "delete",
      url: `/api/provision/system/${serial}/`,
      data: { password },
    })
      .then(async () => {
        setSystemId(null);
        setSystemName(null);
        setReload(true);
      })
      .catch((e) => {
        if (e.response && e.response.status === 403) {
          history.push("/Login");
        } else {
          console.log(e);
          setError(e);
        }
      });
  };

  const handleOpenDeleteDialog = (system) => {
    setSystemId(system.id);
    setSystemName(system.name);
    setDeleteDialogIsOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setSystemId(null);
    setSystemName(null);
    setDeleteDialogIsOpen(false);
  };

  const handleClearError = () => {
    setError(null);
  };

  return (
    <div className="Systems">
      <h3>Systems</h3>
      {error && (
        <div>
          <div>Error: {error.message}</div>
          <div>
            <Button variant="outlined" onClick={handleClearError}>
              Clear Error
            </Button>
          </div>
        </div>
      )}
      {!error && (
        <React.Fragment>
          {dataLoading && (
            <div className={classes.circularProgress}>
              <CircularProgress />
            </div>
          )}
          {!dataLoading && (
            <React.Fragment>
              {deleteDialogIsOpen && (
                <DeleteSystemDialog
                  isOpen={deleteDialogIsOpen}
                  handleClose={handleCloseDeleteDialog}
                  handleDelete={handleDeleteSystem}
                  name={systemName}
                  id={systemId}
                />
              )}
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Customer E-Mail</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.customer_name}</TableCell>
                        <TableCell>{row.customer_email}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => handleOpenDeleteDialog(row)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Systems;
