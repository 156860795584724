import axios from "../../axios";
import filesize from "filesize";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import CrossIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
  clearIcon: {
    color: "#00ff00",
  },
  crossIcon: {
    color: "#ff0000",
  },
  circularProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
});

const ErrorReports = (props) => {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(true);
  const [sortByField, setSortByField] = useState(null);
  const [order, setOrder] = useState(null);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading(true);
      const url =
        sortByField && order
          ? `/api/report/?field=${sortByField}&order=${order}`
          : "/api/report/";
      await axios
        .get(url)
        .then((r) => {
          setData(r.data);
          setDataLoading(false);
          setReload(false);
        })
        .catch((e) => {
          if (e.response && e.response.status === 403) {
            history.push("/Login");
          } else {
            console.log(e);
            setError(e);
          }
        });
    };
    if (reload) {
      fetchData();
    }
  }, [reload, history, sortByField, order]);

  const handleClearError = () => {
    setError(null);
  };

  const handleSortClick = async (field) => {
    let sortOrder;
    if (sortByField === field) {
      sortOrder = order === "asc" ? "desc" : "asc";
    } else {
      sortOrder = "asc";
      setSortByField(field);
    }
    setOrder(sortOrder);
    setReload(true);
  };

  //   const handleDownloadReport = async (id) => {
  //     await axios.get(`/api/reporting/download/${id}/`).catch((e) => {
  //       if (e.response && e.response.status === 403) {
  //         history.push("/Login");
  //       } else {
  //         console.log(e);
  //         setError(e);
  //       }
  //     });
  //   };

  const getIcon = (field) => {
    if (field) {
      return <CheckIcon className={classes.clearIcon} />;
    }
    return <CrossIcon className={classes.crossIcon} />;
  };

  const parseDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div className="Reports">
      <h3>Reports</h3>
      {error && (
        <div>
          <div>Error: {error.message}</div>
          <div>
            <Button variant="outlined" onClick={handleClearError}>
              Clear Error
            </Button>
          </div>
        </div>
      )}
      {!error && (
        <React.Fragment>
          {dataLoading && (
            <div className={classes.circularProgress}>
              <CircularProgress />
            </div>
          )}
          {!dataLoading && (
            <React.Fragment>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={sortByField === "system"}
                          direction={sortByField === "system" ? order : "asc"}
                          onClick={() => handleSortClick("system")}
                        >
                          System ID
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortByField === "bundle_uploaded"}
                          direction={
                            sortByField === "bundle_uploaded" ? order : "asc"
                          }
                          onClick={() => handleSortClick("bundle_uploaded")}
                        >
                          Bundle Uploaded
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortByField === "bundle_processed"}
                          direction={
                            sortByField === "bundle_processed" ? order : "asc"
                          }
                          onClick={() => handleSortClick("bundle_processed")}
                        >
                          Bundle Processed
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortByField === "bundle_size"}
                          direction={
                            sortByField === "bundle_size" ? order : "asc"
                          }
                          onClick={() => handleSortClick("bundle_size")}
                        >
                          Bundle Size
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortByField === "created_at"}
                          direction={
                            sortByField === "created_at" ? order : "asc"
                          }
                          onClick={() => handleSortClick("created_at")}
                        >
                          Created At
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortByField === "updated_at"}
                          direction={
                            sortByField === "updated_at" ? order : "asc"
                          }
                          onClick={() => handleSortClick("updated_at")}
                        >
                          Modified At
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortByField === "message"}
                          direction={sortByField === "message" ? order : "asc"}
                          onClick={() => handleSortClick("message")}
                        >
                          Message
                        </TableSortLabel>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.system}</TableCell>
                        <TableCell>{getIcon(row.bundle_uploaded)}</TableCell>
                        <TableCell>{getIcon(row.bundle_processed)}</TableCell>
                        <TableCell>{filesize(row.bundle_size)}</TableCell>
                        <TableCell>{parseDate(row.created_at)}</TableCell>
                        <TableCell>{parseDate(row.updated_at)}</TableCell>
                        <TableCell>{row.message || ""}</TableCell>
                        <TableCell>
                          {/* <Button
                            variant="outlined"
                            onClick={() => handleDownloadReport(row.id)}
                          >
                            Download
                          </Button> */}
                          <a href={`/api/reporting/download/${row.id}/`}>
                            Download
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default ErrorReports;
