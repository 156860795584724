import axios from "../../axios";
import filesize from "filesize";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  provisionForm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "5px",
  },
  input: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  formControl: {
    minWidth: "229px",
    maxWidth: "229px",
  },
  circularProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
});

const Provision = (props) => {
  const [provisionedSystem, setProvisionedSystem] = useState(null);
  const [packages, setPackages] = useState([]);
  const [systemId, setSystemId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [custEmail, setCustEmail] = useState(null);
  const [error, setError] = useState(null);
  const [availablePackages, setAvailablePackages] = useState(null);
  const [loadingPackages, setLoadingPackages] = useState(true);

  useEffect(() => {
    const loadPackages = async () => {
      setLoadingPackages(true);
      await axios
        .get("/api/provision/packages")
        .then((r) => {
          setAvailablePackages(r.data.packages);
          setLoadingPackages(false);
        })
        .catch((e) => setError(e));
    };

    if (availablePackages === null) {
      loadPackages();
    }
  }, [availablePackages]);

  const history = useHistory();

  const classes = useStyles();

  const handlePackageSelect = (e) => {
    const selectedPackages = e.target.value;
    console.log(selectedPackages);

    setPackages(selectedPackages);
  };

  const handleFieldEnter = (e, f) => {
    f(e.target.value);
  };

  const handleClearError = () => {
    setError(null);
  };

  const handleProvision = async () => {
    const system = {
      packages,
      systemid: systemId,
      firstName,
      lastName,
      custEmail,
    };
    await axios
      .post("/api/provision/system", system)
      .then((r) => {
        console.log(r);
        setPackages([]);
        setProvisionedSystem(r.data);
      })
      .catch((e) => {
        if (e.response && e.response.status === 403) {
          history.push("/Login");
        } else {
          console.log(e);
          setError(e);
        }
      });
  };

  return (
    <div>
      <h3>Provision</h3>
      {error && (
        <div>
          <div>Error: {error.message}</div>
          <div>
            <Button variant="outlined" onClick={handleClearError}>
              Clear Error
            </Button>
          </div>
        </div>
      )}
      {!error && !loadingPackages && (
        <React.Fragment>
          {!provisionedSystem && (
            <div className={classes.provisionForm}>
              <div>
                <TextField
                  label="System ID"
                  variant="outlined"
                  required
                  onChange={(e) => handleFieldEnter(e, setSystemId)}
                  fullWidth
                />
              </div>
              <div>
                <FormControl className={classes.formControl}>
                  <Select
                    value={packages}
                    onChange={handlePackageSelect}
                    input={
                      <Input
                        className={classes.input}
                        fullWidth
                        label="Packages"
                      />
                    }
                    renderValue={(selected) => selected.join(", ")}
                    multiple
                  >
                    {availablePackages.map((pkg, index) => (
                      <MenuItem key={index} value={pkg.name}>
                        <Checkbox checked={packages.includes(pkg.name)} />
                        <ListItemText
                          primary={`${pkg.name} (v${pkg.version}, ${filesize(
                            pkg.size
                          )})`}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <TextField
                  label="Customer First Name"
                  variant="outlined"
                  required
                  onChange={(e) => handleFieldEnter(e, setFirstName)}
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label="Customer Last Name"
                  variant="outlined"
                  required
                  onChange={(e) => handleFieldEnter(e, setLastName)}
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label="Customer Email"
                  variant="outlined"
                  required
                  onChange={(e) => handleFieldEnter(e, setCustEmail)}
                  fullWidth
                />
              </div>
              <div className={classes.button}>
                <Button
                  variant="outlined"
                  disabled={
                    packages.length === 0 ||
                    !systemId ||
                    !firstName ||
                    !lastName ||
                    !custEmail
                  }
                  onClick={handleProvision}
                >
                  Provision
                </Button>
              </div>
            </div>
          )}
          {provisionedSystem && (
            <div>
              <h4>
                System has been provisioned; please note the new device
                credentials:
              </h4>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>System Serial Number</TableCell>
                      <TableCell>System API Key</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {provisionedSystem.serial}
                      </TableCell>
                      <TableCell>{provisionedSystem.password}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <div className={classes.button}>
                <Button
                  variant="outlined"
                  onClick={() => setProvisionedSystem(null)}
                >
                  Provision Another System
                </Button>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      {!error && loadingPackages && (
        <div className={classes.circularProgress}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Provision;
