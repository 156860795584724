import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FLIR from "./FLIR.svg";

const useStyles = makeStyles({
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 24px",
  },
  logoIcon: {
    display: "block",
    height: "30px",
    margin: "0 2px",
  },
});

const Logo = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.logo}>
      <img className={classes.logoIcon} src={FLIR} alt="FLIR Logo" />
    </div>
  );
};

export default Logo;
